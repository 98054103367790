import React, { useEffect } from "react";
import NavBar from "../static/NavBar";
import Footer from "../static/Footer";
import funds from "../../assets/funds.svg";
import local from "../../assets/local.svg";
import asset from "../../assets/asset.svg";
import risk from "../../assets/risk.svg";
import consumer from "../../assets/Consumer loan.jpeg";
import consulting from "../../assets/consulting.svg";
import project from "../../assets/house.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import GetStartedButton from "../GetStartedButton";

const OurServices = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <NavBar />

      <div id="ourservices">
        <div class="bg-white" id="ourservices">
          <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 servi-part lg:px-8">
            <div class="max-w-3xl mx-auto text-center">
              <h2 class="text-5xl font-bold text-neutral-700">Our Services</h2>
            </div>

            {/*==================CONSUMER IMAGE PART========================*/}
            <div className="imag-lt">
              <div class="relative bg-white overflow-hidden">
                <div class="relative">
                  <div class="lg:mx-auto lg:max-w-7xl lg:pl-8 lg:pr-16 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div class="cons-trans" data-aos="zoom-out">
                      <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                          <div class="mt-6">
                            <h2 class="text-5xl font-bold tracking-tight text-gray-900">
                              Consumer Loans
                            </h2>
                            <p class="mt-4 text-base text-gray-500 text-justify">
                              We provide consumer loans to individual clients to
                              facilitate personal and business needs of our
                              clients.
                            </p>
                            <GetStartedButton buttonTitle="Apply for a loan" />

                            {/* <div class="mt-6">
                              <h3 className="text-base text-neutral-700 font-normal space-x-1 loan-text">
                                Apply for a loan
                                <a
                                  href="contactus"
                                  class="text-base font-medium text-red-600 
									hover:text-red-600"
                                >
                                  <span
                                    aria-hidden="true"
                                    className="arow-l rounded-2xl bg-pink-200"
                                  >
                                    {" "}
                                    &#10140;
                                  </span>
                                </a>
                              </h3>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cons-img lg:bg-left-bottom">
                      <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-2">
                        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                          <img
                            className="w-full shadow-xl lg:relative lg:right-0 
												lg:h-full lg:w-auto lg:max-w-none"
                            src={consumer}
                            alt="consumer"
                            data-aos="slide-down"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="relative">
                  <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:ml-20 lg:px-0 lg:col-start-2">
                      <div>
                        <div class="mt-6">
                          <h2 class="text-5xl font-bold tracking-tight text-gray-900">
                            Consulting Services
                          </h2>
                          <p class="mt-4 text-base text-gray-500 text-justify">
                            Paradigm Finance offers business consulting services
                            to its clients using a value depth model that speaks
                            to the core requirements of each client.
                          </p>
                          <GetStartedButton buttonTitle="Get Started" />
                        </div>
                      </div>
                    </div>
                    <div class="mt-12 sm:mt-16 lg:-mt-12 lg:col-start-1">
                      <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                        <img
                          class="w-full lg:relative 
												lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                          src={consulting}
                          alt="Consulting"
                          data-aos="slide-up"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/*==================LOCAL IMAGE PART========================*/}
                <div class="relative bg-white overflow-hidden">
                  <div class="relative">
                    <div class="lg:mx-auto lg:max-w-7xl lg:pl-8 lg:pr-16 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                      <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                          <div class="mt-6">
                            <h2 class="text-5xl font-bold tracking-tight text-gray-900">
                              Fund Management
                            </h2>
                            <p class="mt-4 text-base text-gray-500 text-justify">
                              We manage your investments/funds optimally for the
                              best possible returns. Our goal in this regard is
                              to meet or exceed our client’s investment
                              objectives.
                            </p>
                            <GetStartedButton buttonTitle="Get Started" />
                          </div>
                        </div>
                      </div>
                      <div className="fund-trans lg:bg-left-bottom">
                        <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-2">
                          <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:-py-16 lg:relative lg:h-full">
                            <img
                              className="w-full lg:relative lg:right-0 
												lg:h-full lg:w-auto lg:max-w-none"
                              src={funds}
                              alt="fund"
                              data-aos="slide-down"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="relative">
                    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                      <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:ml-20 lg:px-0 lg:col-start-2">
                        <div>
                          <div class="mt-6">
                            <h2 class="text-5xl font-bold tracking-tight text-gray-900">
                              Project Finance
                            </h2>
                            <p class="mt-4 text-base text-gray-500 text-justify">
                              We provide financial resources for specific
                              projects to our clients. It covers the provision
                              of finance for projects promoted by small and
                              medium scale ventures, construction, and the
                              provision of infrastructure based on public/
                              private partnerships and concessions.
                            </p>
                            <GetStartedButton buttonTitle="Get Started" />
                          </div>
                        </div>
                      </div>
                      <div class="mt-12 sm:mt-16 lg:-mt-12 lg:col-start-1">
                        <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                          <img
                            class="w-full lg:relative 
												lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                            src={project}
                            alt="project"
                            data-aos="slide-down"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*==================ASSET IMAGE PART========================*/}
                <div class="relative bg-white overflow-hidden">
                  <div class="relative">
                    <div class="lg:mx-auto lg:max-w-7xl lg:pl-8 lg:pr-16 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                      <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                          <div class="mt-6">
                            <h2 class="text-5xl font-bold tracking-tight text-gray-900">
                              Local & International Trade
                            </h2>
                            <p class="mt-4 text-base text-gray-500 text-justify">
                              Paradigm Finance offers business consulting
                              services to its clients using a value depth model
                              that speaks to the core requirements of each
                              client.
                            </p>
                            <GetStartedButton buttonTitle="Get Started" />
                          </div>
                        </div>
                      </div>
                      <div className="localtrans bg-left-bottom">
                        <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-2">
                          <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:-py-16 lg:relative lg:h-full">
                            <img
                              className="w-full shadow-xl lg:relative lg:right-0 
												lg:h-full lg:w-auto lg:max-w-none"
                              src={local}
                              alt="local"
                              data-aos="slide-down"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="relative">
                    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                      <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:ml-20 lg:px-0 lg:col-start-2">
                        <div>
                          <div class="mt-6">
                            <h2 class="text-5xl font-bold tracking-tight text-gray-900">
                              Asset Finance
                            </h2>
                            <p class="mt-4 text-base text-gray-500 text-justify">
                              Our flexible business assets acquisition scheme
                              offers our clients opportunities to acquire
                              critical equipments and machineries for their
                              operations.
                            </p>
                            <GetStartedButton buttonTitle="Get Started" />
                          </div>
                        </div>
                      </div>
                      <div class="mt-12 sm:mt-16 lg:-mt-12 lg:col-start-1">
                        <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                          <img
                            class="w-full lg:relative 
												lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                            src={asset}
                            alt="asset"
                            data-aos="slide-down"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OurServices;

import React, { Fragment } from "react";
import logo from "../../assets/logo.png";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

//const navigation = [
//  { name: 'Our Services', href: '/ourservices', current: false },
//  { name: ' Our Team', href: '/ourteam', current: false },
//  { name: 'About Us', href: '/aboutus', current: false },
//  { name: 'Contact Us', href: '/contactus', current: false },
//];

const NavBar = () => {
  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="w-screen h-[80px] z-10 drop-shadow-sm mx-auto px-4 sm:px-6 xl:px-8 lg:px-0">
            <div className="flex items-center justify-between h-full w-full">
              <Link className="flex-shrink-0" to="/">
                <img
                  className="block h-full w-full px-12 lg:px-12 sm:px-0"
                  src={logo}
                  alt="paradigm"
                />
              </Link>
              <div className="flex items-center">
                <div className="hidden xl:ml-24 lg:ml-0 sm:block md:flex md:justify-between sm:ml-6">
                  <div className="flex space-x-4">
                    {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                    <Link
                      to="/ourservices"
                      className=" text-neutral-700 	hover:bg-red-600 hover:text-white px-3 py-2 lg:px-1 md:px-1 rounded-md text-sm font-medium"
                    >
                      Our Services
                    </Link>
                    <Link
                      to="/ourteam"
                      className="text-neutral-700 hover:bg-red-600 hover:text-white lg:px-1 px-3 py-2 md:px-1 rounded-md text-sm font-medium"
                    >
                      Our Team
                    </Link>
                    <Link
                      to="/aboutus"
                      className="text-neutral-700 hover:bg-red-600 hover:text-white lg:px-1 px-3 py-2 md:px-1 rounded-md text-sm font-medium"
                    >
                      About Us
                    </Link>
                    <Link
                      to="/contactus"
                      className="text-neutral-700 hover:bg-red-600 hover:text-white lg:px-1 px-3 py-2 md:px-1 rounded-md text-sm font-medium"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="inline-flex mr-6 items-center uppercase px-4 py-2 border 
										border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                    onClick={(e) => {
                      window.location.href =
                        "mailto:customercare@paradigmfinance.ng";
                      e.preventDefault();
                    }}
                  >
                    Contact Sales
                  </button>
                  <button
                    type="button"
                    className=" inline-flex mr-16 items-center uppercase px-4 py-2 border 
										border-red-500 text-xs font-medium rounded text-red-600"
                    onClick={() =>
                      window.open(
                        `https://paradigmlos.bankbook.cloud`,
                        "_blank"
                      )
                    }
                  >
                    Apply for Loan
                  </button>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center mr-5 justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <Disclosure.Button
                as="a"
                href="/ourservices"
                className=" text-neutral-700 hover:bg-neutral-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                Our Services
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/ourteam"
                className="text-neutral-700 hover:bg-neutral-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                Our Team
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/aboutus"
                className="text-neutral-700 hover:bg-neutral-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                About Us
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/contactus"
                className="text-neutral-700 hover:bg-neutral-700 hover:text-white block px-3 py-2  rounded-md text-base font-medium"
              >
                Contact Us
              </Disclosure.Button>
            </div>
            <div>
              <div className="pt-2 pb-3">
                <div className="px-5">
                  <button
                    type="button"
                    className="inline-flex mr-6 items-center cont-sl uppercase px-3 py-2 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                  >
                    Contact Sales
                  </button>
                  <div className="">
                    <button
                      type="button"
                      className=" inline-flex items-center uppercase pb-2 px-3 py-2 border border-red-500 text-xs font-medium rounded text-red-600"
                    >
                      Loan Calculator
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;

import React, { useEffect } from "react";
import {
  LightBulbIcon,
  BadgeCheckIcon,
  ScaleIcon,
  UserGroupIcon,
} from "@heroicons/react/solid";
import NavBar from "../static/NavBar";
import Footer from "../static/Footer";
import about from "../../assets/about.svg";
import mission from "../../assets/mission.svg";
import vision from "../../assets/vision.svg";
import icon1 from "../../assets/icon1.svg";
import icon2 from "../../assets/icon2.svg";
import icon3 from "../../assets/icon3.svg";
import icon4 from "../../assets/icon4.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import Option from "./Option";

const AboutUs = () => {
  const features = [
    {
      name: "Integrity",
      description:
        "Our word is our bond and we understand that our promise is our brand. We keep our promises.",
      icon: icon1,
    },
    {
      name: "People First",
      description:
        "People come first at Paradigm Finance. We believe it all starts with people and ends with people!",
      icon: icon2,
    },
    {
      name: "Innovation",
      description:
        "We believe in continuous learning and development with a vision of infinite ways to improve on service creation, distribution and delivery through transformative technologies.",
      icon: icon3,
    },
    {
      name: "Excellence",
      description:
        "We believe in the distinction of delivery. We aim to serve each stakeholders excellently",
      icon: icon4,
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <NavBar />
      <div className='bg-white'>
        <div
          className='mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 
				lg:py-16 overflow-hidden abouttrans'>
          <div className='relative'>
            <h2 className='text-center text-6xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl'>
              About Us
            </h2>
          </div>

          {/*==================ABOUT US IMAGE PART========================*/}
          <div className='bg-rgba(242, 242, 242, 1) py-10 dribid'>
            <div class='relative lg:mt-4  lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center'>
              <div class='relative ml-16 abt'>
                <h3 class='text-4xl font-bold text-gray-900 text-left tracking-tight sm:text-3xl'>
                  PARADIGM FINANCE AT A GLANCE
                </h3>
                <p class='mt-3 text-lg text-gray-700 text-justify'>
                  Paradigm Finance is a digitally enabled finance company in
                  Nigeria that provides finance to mass and middle-income
                  employees and structured businesses in selected markets. It
                  was licensed in 2017 by the Central Bank Of Nigeria(CBN) and
                  has witnessed steady business growth over the years.{" "}
                </p>
              </div>

              <div class='mt-10 relative lg:mt-0' aria-hidden='true'>
                <img
                  className='relative mx-auto lg:relative'
                  width='490'
                  src={about}
                  alt='about'
                  data-aos='slide-down'
                />
              </div>
            </div>
          </div>
          {/*==================OUR VISION AND MISSION PART========================*/}


          <div
            className='space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl 
						xl:max-w-none mt-40 ml-14 visiont sm:ml-0'
            data-aos='slide-right'
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'start',
            }}>
            <div class='absolute flex items-center justify-center h-20 w-20 ml-5 mt-5 rounded-full bg-gray-200 text-white'>
              <img
                className='w-10 h-10'
                src={vision}
                alt='mission'
                data-aos=''
              />
            </div>
            <div className='ml-16 w-400'>
              <h2 className='text-5xl font-bold text-neutral-700 tracking-tight sm:text-4xl px-14'>
                Our Vision
              </h2>
              <p service className='text-lg ml-14 mt-5 text-gray-700 text-left'>
                To become a finance company of repute and dependability with
                presence in at least 3 regional zones of Nigeria in 5
                years(2027), using a fairly motivated work force driven by
                technology and give satisfaction to our clients.
              </p>
            </div>
          </div>
          <Option
            title="Our Mission"
            icon={mission}
            desc="   Our vision is to be recognized as the most dependable funding
                option for our clients. We want to be acknowledged for the
                quality of our people and the excellent returns we provide our
                investors."
          />

          {/*==================OUR CORE VALUES PART========================*/}
          <h2
            className='text-5xl font-bold text-neutral-900 text-center pt-80 overflow-hidden'
            data-aos='slide-down'>
            Our Core Values
          </h2>

          <div className='mt-24'>
            <dl className='space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10'>
              {features.map((feature) => (
                <div
                  className='space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl 
                      xl:max-w-none ml-14'
                  data-aos='slide-right'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}>
                  <div className='absolute flex items-center justify-center h-20 w-20 rounded-full bg-gray-200 text-white'>
                    <img
                      className='w-12 h-12'
                      src={feature.icon}
                      alt='mission'
                      data-aos=''
                    />
                  </div>
                  <div className='ml-10 w-400'>
                    <h2 className='text-lg font-bold text-neutral-700 tracking-wider px-14'>
                      {feature.name}
                    </h2>
                    <p
                      service
                      className='text-lg ml-14 mt-5 text-gray-700 text-justify'>
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;

import React, { useEffect } from "react";
import NavBar from "../static/NavBar";
import Footer from "../static/Footer";
import image1 from "../../assets/image1.svg";

import image2 from "../../assets/image2.svg";
import image3 from "../../assets/ty 1.svg";
import image4 from "../../assets/image4.svg";
import image5 from "../../assets/image5.svg";
import image6 from "../../assets/image6.svg";
import image7 from "../../assets/image7.svg";
import image8 from "../../assets/uz.svg";

import chairman from "../../assets/chairman.svg";
import director1 from "../../assets/director1.svg";
import director2 from "../../assets/director2.svg";
import director3 from "../../assets/director3.svg";
import director4 from "../../assets/director4.svg";
import sec from "../../assets/sec.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import ProfileBox from "../ProfileBox";
import ManagementBox from "../ManagementBox";

const OurTeam = () => {
  const teamData = [
    {
      name: "Mr. Charles Nwakanma Onwunali",
      title: "Chairman",
      image: chairman,
      story: `Charles onwunali is an astute economist, entrepreneur and a financial consultant. He is a director of
      several companies in nigeria with degrees in Economics and  Masters in Business Administration
      from the University of Lagos.
      He rose to senior &amp; general management level in treasury and financial services divisions of various
      banks in nigeria and served in FGN PPP that saw to the successful hosting of COJA fiesta at Abuja in
      2003. He was an elected Chairman of Aboh Mbaise Local Government Area in 2010 and later was
      appointed Executive Chairman of Imo Internal Revenue Service that operationalized the autonomy
      of the states revenue service for the first time.`,
    },
    {
      name: "Mrs. Mildred Ifeyinwa Amuchie",
      title: "Director",
      image: director3,
      story: `Mildred Amuchie is the Director and Founder of Reeds and Scents Ltd; a contemporary furniture and
      allied household products company in lekki, Lagos since 2014. She is a customer service expert with
      experience leading world class teams in the Financial Services Industry to strengthen their customer
      relations. She graduated from the University of Nigeria, Nsukka with a bachelor of arts (B.A) English
      in 1996 and obtained a master’s degree in international law and diplomacy in 1998 from the
      university of Lagos. She has various professional qualifications on designs and applied arts from
      recognized institutions of learning.`,
    },
    {
      name: "Mr. AUGUSTINE NNAMDI ODAFI",
      title: "Director",
      image: director2,
      story: `Augustine Odafi holds a Bsc degree in Economics from the University of Benin and a
      Master’s Degree in Business Administration from Enugu State University of Science &amp;
      Technology Business School. He began his working career with the United Bank for Africa
      Plc and moved to Gilt Bond Finance&amp; Securities Ltd as Treasury Officer. In 1990, he joined
      Network Mortgage Finance (Mortgage Bankers) as Senior Treasury Officer where he rose to
      be the Treasury Manager. Between 1993 and 1998, he served as the Head, Treasury
      Operation Group, and MD/CEO Parklane Securities &amp; Trust Limited. In 2004, he took up
      appointment as a Mortgage Adviser/Financial Consultant to the Commonwealth Consortium
      (UK) for 7 years. He is currently the CEO of Worldscope Media &amp; Telecoms Limited, a
      company he founded in 2011.`,
    },
    {
      name: "Mr. Kayode Ayeni (FCA)",
      title: "Director",
      image: director4,
      story: `Kayode Ayeni is the Chief Executive Officer of Afidev Capital Partners &amp; Sponsor, Gulf of Guinea
      Energy Fund. He has served as adviser to the Federal Government of Nigeria and as financial analyst
      on occasions of critical national reforms. In 2002, he joined First Funds Ltd as the MD/CEO. He
      trained with Arthur Anderson &amp; Co in 1986 to qualify as a chartered accountant and he holds a first
      class degree in accountancy from the Obafemi Awolowo University. He obtained an M.Sc in Financial
      Economics from University of London, (UK) and an MBA from the University of Lagos.`,
    },
    {
      name: "Mr. Chukwumankpam A. Onwunali",
      title: "Director",
      image: director1,
      story: `Chukwumankpam A. Onwunali is a consummate professional with a proven background in
      marketing, brand management, finance and financial controls, commercial advisory services,
      procurement and personnel management across multiple business verticals and especially in
      the Oil and Gas industry. He has led complex oil &amp; gas divestments, acquisitions , equipment
      &amp; facilities leasing.
      He has led multiple teams successfully over the course of his career with Nigerian Breweries
      Plc, Shell Petroleum Development Company, and Aiteo Eastern (exploration &amp; production)
      Company Ltd as lead commercial, a position he holds till date. He is a graduate of Economics
      from the University of Calabar, an associate of the Chartered Institute of Accountants of
      Nigeria (ICAN); Chartered Institute of Investment Analysts (UK); Chartered Institute of
      Taxation of Nigeria; Association of International Petroleum Negotiators, Nigerian Institute of
      Management; Nigerian Gas Association and Society of Petroleum Engineers.`,
    },
    {
      name: "Mr. Alozie A. Agada",
      title: "Director",
      image: director2,
      story: `Mr alozie agada is currently the strategic planning coordinator for the Arco group, a leader in
      machinery maintenance, marine crew vessels supply and QC/QA service support in the Nigerian oil &amp;
      gas industry. He started his career at NNPC refinery, Warri and joined Exxonmobil Development,
      Houston, United States of America in 1981 in a career that spanned multiple areas of oil exploration
      
      and production. He has led several oil and gas development projects in Nigeria, Venezuela, US,
      France, Malaysia and Indonesia.
      He was consultant to the Delta State Government on the establishment of Warri industrial Park Ltd
      and project technical consultant/engineering manager Foxtrot International, Cote d’Ivoire. He
      attended the university of Lagos and graduated with a bachelor’s degree in mechanical engineering
      in 1997. He is a COREN registered engineer and a fellow of the Nigerian Society of Engineers.`,
    },
    {
      name: "Mrs. Frances Onwunali",
      title: "Company Secretary",
      image: sec,
      story: `Frances Onwunali is the Principal Partner of F. O. Onwunali &amp; Co. She holds a Bachelor of Law
      Degree from Lagos State University and was called to the Nigerian Bar in 2008. She also has training
      in Mediation, Arbitration and a Diploma in Secretarial Administration. She worked with Union Bank
      PLC and Nigerian Intercontinental Merchant Bank Ltd (later Intercontinental Bank PLC). Her career
      also took her through the Law Firms of Adenrele Tokunbo Kehinde &amp; Co and Adedeji, Owotomo &amp;
      Associates before setting up her Law Firm. She is a member of multiple Professional Bodies,
      including the Nigerian Bar Association, Section on Business Law, International Federation of Women
      Lawyers (FIDA) and the Immigration Lawyers Forum (ILAF)`,
    },
  ];

  const internalAudit = [
    {

      name: "MR UZOESHI KINGSLEY C",
      title: "Internal Audit",
      image: image8,
      story: `Kingsley has over 10 years of working experience as an Internal Audit and Control Specialist,
      these cuts across financial services and retail industry. He is an alumnus of the Federal
      Polytechnic Nekede (HND), a graduate of accounting and a student member at the Institute
      of Chartered Accountants of Nigeria (ICAN).
      `,
    },
  ];

  const managementTeamData = [
    {
      name: "Mr. George Nwachukwu, (FCA)",
      title: "Executive Director",
      image: image2,
      story: `George Nwachukwu, ACA is a member of the Institute of Chartered Accountants of Nigeria
      and an alumnus of the University of Port Harcourt from where he obtained a Bachelor of
      Science degree in economics in 1992. He started his career as an operations officer with
      exchange biz Ltd in 1994. He joined Global Assets Ltd as treasury officer in 2000 and in
      2006 he moved to Citihomes Savings &amp; Loans as Head of Treasury. In 2009 he set up
      Valuelink Company Ltd, an investment and financial services firm where he was the CEO up
      till 2016. He joined Paradigm Finance Ltd as the General Manager in June 2016 after which
      he was appointed as MD/CEO in 2018. He currently serves as an Executive Director at
      Paradigm Finance Ltd.`,
    },
    {
      name: "Mr. Oluwafemi I. Oyetayo, (ACA)",
      title: "Finance and Accounts",
      image: image3,
      story: `Oluwafemi is a Member of the Institute of Chartered Accountants of Nigeria who has used his ability
      to motivate, Inspire and lead groups of individuals with outstanding knowledge of financial reporting
      systems. He has over eight years of experience working in diverse financial positions with multiple
      companies, a few of which are Elyon Pro Services, Telinno Consulting, and Tsebo Rapid Nigeria. His
      wealth of experience enables him to gain extensive insight into the accounting and financial services
      field.`,
    },
    {
      name: "Mr. Chukwuemeka Anyanwu",
      title: "Credit Risk and Underwriting",
      image: image4,
      story: `Chukwuemeka holds a B.Sc in Insurance and Acturial Sciences from the Imo State
      University. He has 7 years work experience across Underwriting, Credit Documentation,
      Claims analysis, and Credit Analysis and Risk Management functions. He also has
      certifications with the CIBN in Professional Conduct and Ethics as well as Digital marketing
      certification.`,
    },

    {
      name: "Josephine Avwenaghagha",
      title: "Legal and Compliance",
      image: image5,
      story: `Josephine Avwenaghagha is a member of the Nigerian Bar Association and the Nigerian
      Institute of Chartered Secretaries and Administrators of Nigeria. She is a graduate of
      University of Benin (LL.B) and the Nigerian Law School (B.L). She has over 5 years
      experience in corporate and company secretarial services. She currently provides legal,
      governance, compliance, reporting and regulatory support at Paradigm Finance Ltd.`,
    },

    {
      name: "Mr. George Okeleye",
      title: "Information and Communications Technology",
      image: image6,
      story: `George has over 10 years experience in Financial services, as Head ICT and technology
      experience; Harvesters Cooperatives, Broadview MFB, Christ Cornerstone Intl College and
      Petra MFB.  He is a graduate of Lagos State Polytechnic (HND) and has Certifications in
      CISCO, WLAN Networking, CCNA+ and Digital Marketing.`,
    },
    {
      name: "Abigail Umoh",
      title: "People Management",
      image: image7,
      story: `Abigail is an astute, young and dynamic professional with over 4 years experience in
      Customer Relations Management, Office and People Management at Vedic Lifecare
      Hospital. She holds a BSc in Insurance from the University of Uyo.`,
    },
  ];
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div>
      <NavBar />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 overflow-hidden lg:py-24">
          <div className="space-y-8 sm:space-y-12">
            <div
              className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl overflow-hidden pt-5"
              data-aos="fade-up"
            >
              <h2 class="text-5xl font-bold tracking-tight sm:text-4xl">
                Our Team
              </h2>
              <p class="text-2xl sm:text-lg">
                Our Team is made up of astute professionals with requisite
                experience, a penchant for digital innovation, and expertise to
                pursue excellent service delivery
              </p>
            </div>
            <div className="board">
              <h3 className="uppercase text-4xl text-black dribid font-bold text-center pt-28">
                BOARD OF DIRECTORS
              </h3>
            </div>
            <ul
              className="space-y-12 mt-12 lg:grid-cols-3 lg:gap-x-4 lg:gap-y-4 sm:grid
							 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 
							"
              data-aos="fade-down"
            >
              {teamData.map((data, index) => (
                <ProfileBox data={data} key={index} />
              ))}
              {/* <ProfileBox data={teamData[0]} />
                <ProfileBox data={teamData[1]} />  */}
            </ul>
          </div>
        </div>
      </div>

      {/*=================================OUR MANAGEMENT TEAM==========================*/}
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-8 sm:space-y-12">
            <div
              className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl"
              data-aos="fade-down"
            >
              <h2 className="text-4xl font-bold text-black tracking-tight sm:text-4xl mb-28 uppercase">
                MANAGEMENT TEAM
              </h2>

            </div>

            <ul className="space-y-16 mt-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-4 lg:gap-y-4 justify-center">

              {managementTeamData
                .slice(1, managementTeamData.length)
                .map((person, index) => (
                  <ManagementBox data={person} key={index} />
                ))}
            </ul>
          </div>
        </div>
      </div>
      {/* ====================================INTERNAL AUDIT TEAM */}
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-8 sm:space-y-12">
            <div
              className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl"
              data-aos="fade-down"
            >
              <h2 className="text-4xl font-bold text-black tracking-tight sm:text-4xl mb-28 uppercase">
                INTERNAL AUDIT
              </h2>

              <ul className="row space-y-16 mt-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-4 lg:gap-y-4">
                {internalAudit.map((person, index) => (
                  <ManagementBox data={person} key={index} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurTeam;

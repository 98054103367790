import React from "react";

import linkedin from "../assets/linkedin.svg";
import "aos/dist/aos.css";
import "../styles.css";

const ManagementBox = ({ data }) => {
  return (
    <div class="content">
      <div className=" front space-y-4 md-pic">
        <div
          className="aspect-w-3 aspect-h-2 lg:aspect-h-0 sm:aspect-w-3 overflow-hidden
										 lg:aspect-w-3"
        >
          <img
            className="object-center w-64"
            src={data.image}
            alt={data.title}
          />
        </div>

        <div className="space-y-2 mtgbx" data-aos="slide-down">
          <div className="leading-6 space-y-1">
            <h3 className="uppercase text-lg font-bold">{data.name}</h3>
            <p class="">{data.title}</p>
          </div>
        </div>
      </div>
      <div class="back">
        <div className="space-y-2">
          <div className="leading-6 mb-16 space-y-1">
            <h3 className="uppercase text-lg font-bold">{data.name}</h3>
            <p class="">{data.title}</p>
            <div class="text-xs">
              <p
                class=""
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  lineHeight: "16.1px",
                }}
              >
                {data.story}
              </p>
            </div>

            {/* <div class="p-6 rounded-bl-2xl flex justify-center rounded-br-2xl md:px-8">
              <ul role="list" class="flex space-x-5">
                <li>
                  <a href="#" class="">
                    <span className="inline-block text-xs font-bold">
                      Read More
                    </span>
                    <span class="sr-only">LinkedIn</span>
                    <img
                      className="w-8 h-8 inline-block ml-3"
                      src={linkedin}
                      alt="linkedin"
                      data-aos="zoom-in"
                    />
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <div class="content">
      <div class="front">
        <li class="sm:py-8">
          <div className="space-y-4">
            <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-3 overflow-hidden lg:aspect-w-3 lg:aspect-h-3">
              <img
                className="object-center"
                src={data.image}
                alt="data.image"
              />
            </div>

            <div className="space-y-2">
              <div className="leading-6 mb-16 space-y-1">
                <h3 className="uppercase text-lg font-bold">{data.name}</h3>
                <p class="">{data.title}</p>
              </div>
            </div>
          </div>
        </li>
      </div>
      <div class="back">
        <div class="text-xs">
          <p class="">{data.story}</p>
        </div>

        <div class="p-6 rounded-bl-2xl flex justify-center rounded-br-2xl md:px-8">
          <ul role="list" class="flex space-x-5">
            <li>
              <a href="#" class="">
                <span className="inline-block text-xs font-bold">
                  Read More
                </span>
                <span class="sr-only">LinkedIn</span>
                <img
                  className="w-8 h-8 inline-block ml-3"
                  src={linkedin}
                  alt="linkedin"
                  data-aos="zoom-in"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div> */
}

export default ManagementBox;

{
  /* <li class="sm:py-2.5">
                <div className="space-y-4">
                  <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-3 overflow-hidden lg:aspect-w-3 lg:aspect-h-3">
                    <img
                      className="object-center"
                      src={image2}
                      alt="executive director"
                      data-aos="slide-down"
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="leading-6 mb-16 space-y-1">
                      <h3 className="uppercase text-lg font-bold">
                        George Nwachukwu
                      </h3>
                      <p class="">Executive Director</p>
                      <div class="text-xs">
                        <p class="">
                          George Nwachukwu, FCA, is a fellow of the Institute of
                          Chartered Accountants of Nigeria and an alumnus of the
                          University of Port Harcourt, where he obtained a
                          Bachelor of Science degree in Economics in 1992. He
                          started his career as an operations officer with
                          Exchange Biz Ltd in 1994.
                        </p>
                      </div>

                      <div class="p-6 rounded-bl-2xl flex justify-center rounded-br-2xl md:px-8">
                        <ul role="list" class="flex space-x-5">
                          <li>
                            <a href="#" class="">
                              <span className="inline-block text-xs font-bold">
                                Read More
                              </span>
                              <span class="sr-only">LinkedIn</span>
                              <img
                                className="w-8 h-8 inline-block ml-3"
                                src={linkedin}
                                alt="linkedin"
                                data-aos="zoom-in"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li class="sm:py-2.5">
                <div className="space-y-4">
                  <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-3 overflow-hidden lg:aspect-w-3 lg:aspect-h-3">
                    <img
                      className="object-center"
                      src={image3}
                      alt="finance"
                      data-aos="slide-down"
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="leading-6 mb-16 space-y-1">
                      <h3 className="uppercase text-lg font-bold">
                        Babatunde Esho
                      </h3>
                      <p class="">Finance and Accounts</p>
                      <div class="text-xs">
                        <p class="">
                          Babatunde has over four years of relevant experience
                          in financial advisory, financial reporting, business
                          finance and accounting operations in the Big
                          4-Deloitte & Touche and Providus Bank. He is a
                          graduate of Accounting from Obafemi Awolowo University
                          and an Associate Chartered Accountant (ICAN).
                        </p>
                      </div>

                      <div class="p-6 rounded-bl-2xl flex justify-center rounded-br-2xl md:px-8">
                        <ul role="list" class="flex space-x-5">
                          <li>
                            <a href="#" class="">
                              <span className="inline-block text-xs font-bold">
                                Read More
                              </span>
                              <span class="sr-only">LinkedIn</span>
                              <img
                                className="w-8 h-8 inline-block ml-3"
                                src={linkedin}
                                alt="linkedin"
                                data-aos="zoom-in"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li class="sm:py-2.5">
                <div className="space-y-4">
                  <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-3 overflow-hidden lg:aspect-w-3 lg:aspect-h-3">
                    <img
                      className="object-center"
                      src={image4}
                      alt="Credit risk"
                      data-aos="slide-down"
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="leading-6 mb-16 space-y-1">
                      <h3 className="uppercase text-lg font-bold">
                        Chukwuemeka Anyanwu
                      </h3>
                      <p class="">Credit Risk and Underwriting</p>
                      <div class="text-xs">
                        <p class="">
                          Charles Onwunali is an Economist, ex-banker, an
                          accomplished entrepreneur and a financial consultant.
                          He is a Director of several companies in Nigeria. He
                          had his university education at the University of
                          Lagos and graduated with a 2nd Class upper degree
                          honors in Economics in 1985.
                        </p>
                      </div>

                      <div class="p-6 rounded-bl-2xl flex justify-center rounded-br-2xl md:px-8">
                        <ul role="list" class="flex space-x-5">
                          <li>
                            <a href="#" class="">
                              <span className="inline-block text-xs font-bold">
                                Read More
                              </span>
                              <span class="sr-only">LinkedIn</span>
                              <img
                                className="w-8 h-8 inline-block ml-3"
                                src={linkedin}
                                alt="linkedin"
                                data-aos="zoom-in"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li class="sm:py-2.5">
                <div className="space-y-4">
                  <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-3 overflow-hidden lg:aspect-w-3 lg:aspect-h-3">
                    <img
                      className="object-center"
                      src={image5}
                      alt="legal"
                      data-aos="slide-down"
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="leading-6 mb-16 space-y-1">
                      <h3 className="uppercase text-lg font-bold">
                        Josephine Avwenaghagha
                      </h3>
                      <p class="">Legal and Compliance</p>
                      <div class="text-xs">
                        <p class="">
                          Josephine Avwenaghagha is a member of the Nigerian Bar
                          Association and the Nigerian Institute of Chartered
                          Secretaries and Administrators of Nigeria. She is a
                          graduate of the University of Benin (LL.B) and the
                          Nigerian Law School (B.L).
                        </p>
                      </div>

                      <div class="p-6 rounded-bl-2xl flex justify-center rounded-br-2xl md:px-8">
                        <ul role="list" class="flex space-x-5">
                          <li>
                            <a href="#" class="">
                              <span className="inline-block text-xs font-bold">
                                Read More
                              </span>
                              <span class="sr-only">LinkedIn</span>
                              <img
                                className="w-8 h-8 inline-block ml-3"
                                src={linkedin}
                                alt="linkedin"
                                data-aos="zoom-in"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li class="sm:py-2.5">
                <div className="space-y-4">
                  <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-3 overflow-hidden lg:aspect-w-3 lg:aspect-h-3">
                    <img
                      className="object-center"
                      src={image6}
                      alt="ict"
                      data-aos="slide-down"
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="leading-6 mb-16 space-y-1">
                      <h3 className="uppercase text-lg font-bold">
                        George Okeleye
                      </h3>
                      <p class="">Information and Communications Technology</p>
                      <div class="text-xs">
                        <p class="">
                          George has over ten years of experience in Financial
                          Services, as Head ICT and technology experience;
                          Harvesters Cooperatives, Broadview MFB, Christ
                          Cornerstone Intl College and Petra MFB. He is a
                          graduate of Lagos State Polytechnic (HND).
                        </p>
                      </div>

                      <div class="p-6 rounded-bl-2xl flex justify-center rounded-br-2xl md:px-8">
                        <ul role="list" class="flex space-x-5">
                          <li>
                            <a href="#" class="">
                              <span className="inline-block text-xs font-bold">
                                Read More
                              </span>
                              <span class="sr-only">LinkedIn</span>
                              <img
                                className="w-8 h-8 inline-block ml-3"
                                src={linkedin}
                                alt="linkedin"
                                data-aos="zoom-in"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li class="sm:py-2.5">
                <div className="space-y-4">
                  <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-3 overflow-hidden lg:aspect-w-3 lg:aspect-h-3">
                    <img
                      className="object-center"
                      src={image7}
                      alt="people management"
                      data-aos="slide-down"
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="leading-6 mb-16 space-y-1">
                      <h3 className="uppercase text-lg font-bold">
                        Abigail Umoh
                      </h3>
                      <p class="">People Management</p>
                      <div class="text-xs">
                        <p class="">
                          Abigail is an astute, young and dynamic professional
                          with over four years of experience in Customer
                          Relations Management, Office and People Management at
                          Vedic Lifecare Hospital. She holds a BSc in Insurance
                          from the University of Uyo.
                        </p>
                      </div>

                      <div class="p-6 rounded-bl-2xl flex justify-center rounded-br-2xl md:px-8">
                        <ul role="list" class="flex space-x-5">
                          <li>
                            <a href="#" class="">
                              <span className="inline-block text-xs font-bold">
                                Read More
                              </span>
                              <span class="sr-only">LinkedIn</span>
                              <img
                                className="w-8 h-8 inline-block ml-3"
                                src={linkedin}
                                alt="linkedin"
                                data-aos="zoom-in"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */
}

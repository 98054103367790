import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import AboutUs from "./components/pages/AboutUs";
import OurServices from "./components/pages/OurServices";
import OurTeam from "./components/pages/OurTeam";
import Contact from "./components/pages/Contact";
import Notfound from "./components/pages/Notfound";

import "./App.css";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/ourservices" element={<OurServices />} />
          <Route exact path="/ourteam" element={<OurTeam />} />
          <Route exact path="/contactus" element={<Contact />} />

          <Route exact path="*" element={<Notfound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

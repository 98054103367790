import React, { useEffect } from "react";
import NavBar from "../static/NavBar";
import Footer from "../static/Footer";
import map from "../../assets/map.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <NavBar />

      <div className="relative bg-white overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2"></div>
        </div>

        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div
            className="py-16 px-4 sm:px-6 lg:col-span-2  lg:py-20 xl:pr-12"
            data-aos="slide-left"
          >
            <div className="max-w-lg mx-auto">
              <h2 className="text-4xl font-bold tracking-tight text-neutral-700 sm:text-3xl">
                We would love to <br />
                hear from you.
              </h2>
              <h4 className="mt-14 text-2xl font-bold font-sans text-neutral-700 leading-6">
                Our Office Address
              </h4>
              <p className="mt-8 text-lg text-neutral-700 font-normal font-sans leading-6 text-justify">
                1st Floor, LAPAL House, Suite 1A & B, Smart Office Suites, 235
                Igbosere Street, Lagos Island, Lagos, Nigeria
              </p>
              <h4 className="mt-14 text-2xl font-sans font-bold text-neutral-700 leading-6">
                Email Us
              </h4>
              <div className="mt-8">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  {/* ======Heroicon name: outline/mail =========*/}
                  <svg
                    className="flex-shrink-0 h-6 w-6 "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="ml-3 text-lg text-neutral-700 font-normal font-sans leading-6">
                    {" "}
                    info@paradigmfinance.ng{" "}
                  </span>
                </dd>
              </div>

              <h4 className="mt-14 text-2xl font-sans font-bold text-neutral-700 leading-6">
                Or Call
              </h4>
              <div className="mt-8">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  {/* Heroicon name: outline/phone */}
                  <svg
                    className="flex-shrink-0 h-6 w-6 "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <span className="ml-3 text-lg text-neutral-700 font-normal font-sans leading-6">
                    {" "}
                    (+234) 091 3938 5546{" "}
                  </span>
                </dd>
              </div>
            </div>
          </div>

          {/*===============FORM PART============*/}
          <div
            className=" lg:w-auto lg:max-w-400 pb-3 bg-white py-5 px-4 sm:px-6 lg:col-span-3 lg:ml-5
            lg:px-12  border shadow-lg rounded-lg"
            style={{}}
          >
            <img
              className="lg:w-auto pb-3 pl-6 map-in lg:max-w-none md:hidden sm:hidden"
              src={map}
              alt="map"
              data-aos="slide-down"
            />

            <div className="max-w-lg mx-auto lg:max-w-none">
              <form
                action="#"
                method="POST"
                className="grid grid-cols-1 gap-y-6"
              >
                <div className="col-span-1">
                  <label
                    for="full-name"
                    className="mb-3 text-sm text-neutral-700 font-normal"
                  >
                    Full name
                  </label>
                  <input
                    type="text"
                    name="full-name"
                    id="full-name"
                    autocomplete="name"
                    className="block w-4/5 py-3 px-4 border placeholder-red-600
										 border-red-600 mt-3 rounded-md"
                  />
                </div>
                <div className="col-span-1">
                  <label
                    for="email"
                    className="text-sm text-neutral-700 font-normal"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    className="block w-full shadow-sm py-3 mt-3 px-4 border
										 placeholder-red-600 border-red-600 rounded-md"
                  />
                </div>
                <div className="col-span-3 mt-8">
                  <label
                    for="message"
                    className="text-sm text-neutral-700 font-normal"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    className="block w-full shadow-sm py-3
										 mt-3 px-4 border placeholder-red-600 border-red-600 rounded-md"
                    placeholder=""
                  ></textarea>
                </div>
              </form>
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ marginTop: "20px" }}>
                  <button
                    type="submit"
                    className="text-center py-3 px-6 border border-transparent 
										shadow-sm text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;

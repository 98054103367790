import React, { useEffect } from "react";
import {
  LightBulbIcon,
  BadgeCheckIcon,
  ScaleIcon,
  UserGroupIcon,
} from "@heroicons/react/solid";
import NavBar from "../static/NavBar";
import Footer from "../static/Footer";
import about from "../../assets/about.svg";
import mission from "../../assets/mission.svg";
import vision from "../../assets/vision.svg";
//import icon1 from '../../assets/icon1.svg';
//import icon2 from '../../assets/icon2.svg';
//import icon3 from '../../assets/icon3.svg';
//import icon4 from '../../assets/icon4.svg';
import AOS from "aos";
import "aos/dist/aos.css";

const Option = ({ title, icon, desc }) => {
  return (
    <div
      className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl 
            xl:max-w-none mt-40 ml-14"
      data-aos="slide-right"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      <div class="absolute flex items-center justify-center h-20 w-20 ml-5 mt-5 rounded-full bg-gray-200 text-white">
        <img className="w-10 h-10" src={icon} alt="mission" data-aos="" />
      </div>
      <div className="ml-16 w-400">
        <h2 className="text-5xl font-bold text-neutral-700 tracking-tight sm:text-4xl px-14">
          {title}
        </h2>
        <p
          service
          className="text-lg ml-14 mt-5 text-gray-700 text-justify"
        >
          {desc}
        </p>
      </div>
    </div>
  );
};

export default Option;

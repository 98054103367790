import React, { useEffect } from "react";
import funds from "../../assets/funds.svg";
import local from "../../assets/local.svg";
import asset from "../../assets/asset.svg";
import consumer from "../../assets/Consumer loan.jpeg";
import consulting from "../../assets/consulting.svg";
import project from "../../assets/house.svg";
import map from "../../assets/map.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import about from "../../assets/about.svg";
import header from "../../assets/header 1.svg";
import NavBar from "../static/NavBar";
import Footer from "../static/Footer";
import GetStartedButton from "../GetStartedButton";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <NavBar />

      {/* ==========================HEADER STARTS ============================= */}
      <main class='lg:relative lg:my-40 md:my-16'>
        <div class='mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-24 lg:text-left px-8 overflow-hidden'>
          <div class='px-4 lg:w-1/2 sm:px-8 xl:pr-16'>
            <h1 class='text-4xl font-bold text-neutral-700 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl'>
              <span class='block'>Your all-in-one</span>
              <span class='block'>finance partner</span>
            </h1>
            <p class='mt-3 max-w-md mx-auto text-lg text-neutral-700 sm:text-xl md:mt-5 md:max-w-3xl text-justify'>
              Paradigm Finance is a digitally enabled finance company in Nigeria
              that provides finance to mass and middle-income employees and
              structured businesses in selected markets
            </p>
            <div class='mt-10 sm:flex sm:justify-center lg:justify-start'>
              <div class='rounded-md'>
                <a
                  href='/'
                  class='w-full flex items-center justify-center px-8 py-3 border border-transparent 
									uppercase text-xs font-bold rounded text-white bg-red-600
									 hover:bg-red-600 md:py-4 md:text-lg lg:text-xs'>
                  {' '}
                  APPLY FOR A LOAN{' '}
                </a>
              </div>
              {/* <div class='mt-3 rounded sm:mt-0 sm:ml-3'>
                <a
                  href='/'
                  class='w-full flex items-center justify-center 
									uppercase px-8 py-3 border border-red-500 text-xs font-bold rounded
									 text-red-600 bg-white 
									hover:bg-red-600 md:py-4 md:text-lg lg:text-xs'>
                  LOAN CALCULATOR
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div
          class='relative w-full h-64 sm:h-72 md:h-96 
				lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full'>
          <img
            class='absolute inset-0 w-11/12 h-full'
            src={header}
            alt='header'
          />
        </div>
      </main>
      {/* ==========================HEADER ENDS ============================= */}

      {/*==================ABOUT US IMAGE PART========================*/}
      <div
        className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:py-24 lg:grid 
			lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24  overflow-hidden'>
        <div className='abouttrans bg-right-bottom'>
          <div className='px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2'>
            <div>
              <div className='mt-6' data-aos='slide-left'>
                <h2 className='text-xl font-extrabold tracking-tight text-gray-900 uppercase'>
                  PARADIGM FINANCE AT A GLANCE
                </h2>
                <p className='mt-4 text-base text-gray-500 text-justify'>
                  Paradigm Finance is a digitally enabled finance company in
                  Nigeria that provides finance to mass and middle-income
                  employees and structured businesses in selected markets. It
                  was licensed in 2017 by the Central Bank Of Nigeria(CBN) and
                  has witnessed steady business growth over the years.{" "}

                </p>
                <GetStartedButton buttonTitle='Read More' />
              </div>
            </div>
          </div>
        </div>
        <div className='mt-12 sm:mt-16 lg:mt-0 lg:col-start-1'>
          <div className='pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
            <img
              className='w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none'
              src={about}
              alt='about us'
              data-aos='fade-down'
            />
          </div>
        </div>
      </div>

      {/*==============================OUR SERVICES PART==============================*/}
      <div class='bg-white' id='ourservices'>
        <div class='max-w-7xl mx-auto py-16 md:pt-40 md:pb-40 px-4 servic-part sm:px-6 sm:py-32 lg:py-24 lg:px-8'>
          <div class='max-w-3xl mx-auto text-center'>
            <h2 class='text-5xl font-bold text-neutral-700'>Our Services</h2>
          </div>

          {/*==================CONSUMER IMAGE PART========================*/}
          <div className='imag-lt'>
            <div class='relative bg-white overflow-hidden'>
              <div class='relative'>
                <div class='lg:mx-auto lg:max-w-7xl lg:pl-8 lg:pr-16 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
                  <div class='cons-trans' data-aos='zoom-out'>
                    <div class='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0'>
                      <div>
                        <div class='mt-6'>
                          <h2 class='text-5xl font-bold tracking-tight text-gray-900'>
                            Consumer Loans
                          </h2>
                          <p class="mt-4 text-base text-gray-500 text-justify">
                            We provide consumer loans to individual client to
                            satisfy personal and business needs of our clients.
                          </p>
                          <GetStartedButton buttonTitle='Apply for a loan' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='cons-img lg:bg-left-bottom'>
                    <div className='mt-12 sm:mt-16 lg:mt-0 lg:col-start-2'>
                      <div className='pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
                        <img
                          className='w-full shadow-xl lg:relative lg:right-0 
												lg:h-full lg:w-auto lg:max-w-none'
                          src={consumer}
                          alt='consumer'
                          data-aos='slide-down'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class='relative'>
                <div class='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
                  <div class='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:ml-20 lg:px-0 lg:col-start-2'>
                    <div>
                      <div class='mt-6'>
                        <h2 class='text-5xl font-bold tracking-tight text-gray-900'>
                          Consulting Services
                        </h2>
                        <p class='mt-4 text-base text-gray-500 text-justify'>
                          Paradigm Finance offers business consulting services
                          to its clients using a value depth model that speaks
                          to the core requirements of each client.
                        </p>
                        <GetStartedButton buttonTitle='Get Started' />
                      </div>
                    </div>
                  </div>
                  <div class='mt-12 sm:mt-16 lg:-mt-12 lg:col-start-1'>
                    <div class='pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
                      <img
                        class='w-full lg:relative 
												lg:right-0 lg:h-full lg:w-auto lg:max-w-none'
                        src={consulting}
                        alt='Consulting'
                        data-aos='slide-up'
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*==================LOCAL IMAGE PART========================*/}
              <div class='relative bg-white overflow-hidden'>
                <div class='relative'>
                  <div class='lg:mx-auto lg:max-w-7xl lg:pl-8 lg:pr-16 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
                    <div class='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0'>
                      <div>
                        <div class='mt-6'>
                          <h2 class='text-5xl font-bold tracking-tight text-gray-900'>
                            Fund Management
                          </h2>
                          <p class='mt-4 text-base text-gray-500 text-justify'>
                            We manage your investments/funds optimally for the
                            best possible returns. Our goal in this regard is to
                            meet or exceed our client’s investment objectives.
                          </p>
                          <GetStartedButton buttonTitle='Get Started' />
                        </div>
                      </div>
                    </div>
                    <div className='fund-trans lg:bg-left-bottom'>
                      <div className='mt-12 sm:mt-16 lg:mt-0 lg:col-start-2'>
                        <div className='pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:-py-16 lg:relative lg:h-full'>
                          <img
                            className='w-full lg:relative lg:right-0 
												lg:h-full lg:w-auto lg:max-w-none'
                            src={funds}
                            alt='fund'
                            data-aos='slide-down'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='relative'>
                  <div class='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
                    <div class='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:ml-20 lg:px-0 lg:col-start-2'>
                      <div>
                        <div class='mt-6'>
                          <h2 class='text-5xl font-bold tracking-tight text-gray-900'>
                            Project Finance
                          </h2>
                          <p class='mt-4 text-base text-gray-500 text-justify'>
                            We provide financial resources for specific projects
                            to our clients. It covers the provision of finance
                            for projects promoted by small and medium scale
                            ventures, construction, and the provision of
                            infrastructure based on public/ private partnerships
                            and concessions.
                          </p>
                          <GetStartedButton buttonTitle='Get Started' />
                        </div>
                      </div>
                    </div>
                    <div class='mt-12 sm:mt-16 lg:-mt-12 lg:col-start-1'>
                      <div class='pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
                        <img
                          class='w-full lg:relative 
												lg:right-0 lg:h-full lg:w-auto lg:max-w-none'
                          src={project}
                          alt='project'
                          data-aos='slide-down'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*==================ASSET IMAGE PART========================*/}
              <div class='relative bg-white overflow-hidden'>
                <div class='relative'>
                  <div class='lg:mx-auto lg:max-w-7xl lg:pl-8 lg:pr-16 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
                    <div class='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0'>
                      <div>
                        <div class='mt-6'>
                          <h2 class='text-5xl font-bold tracking-tight text-gray-900'>
                            Local & International Trade
                          </h2>
                          <p class='mt-4 text-base text-gray-500 text-justify'>
                            Paradigm Finance offers business consulting services
                            to its clients using a value depth model that speaks
                            to the core requirements of each client.
                          </p>
                          <GetStartedButton buttonTitle='Get Started' />
                        </div>
                      </div>
                    </div>
                    <div className='localtrans bg-left-bottom'>
                      <div className='mt-12 sm:mt-16 lg:mt-0 lg:col-start-2'>
                        <div className='pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:-py-16 lg:relative lg:h-full'>
                          <img
                            className='w-full shadow-xl lg:relative lg:right-0 
												lg:h-full lg:w-auto lg:max-w-none'
                            src={local}
                            alt='local'
                            data-aos='slide-down'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='relative'>
                  <div class='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
                    <div class='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:ml-20 lg:px-0 lg:col-start-2'>
                      <div>
                        <div class='mt-6'>
                          <h2 class='text-5xl font-bold tracking-tight text-gray-900'>
                            Asset Finance
                          </h2>
                          <p class='mt-4 text-base text-gray-500 text-justify'>
                            Our flexible business assets acquisition scheme
                            offers our clients opportunities to acquire critical
                            equipments and machineries for their operations.
                          </p>
                          <GetStartedButton buttonTitle='Get Started' />
                        </div>
                      </div>
                    </div>
                    <div class='mt-12 sm:mt-16 lg:-mt-12 lg:col-start-1'>
                      <div class='pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
                        <img
                          class='w-full lg:relative 
												lg:right-0 lg:h-full lg:w-auto lg:max-w-none'
                          src={asset}
                          alt='asset'
                          data-aos='slide-down'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*==========================CONTACT FORM PART============*/}
      <div className='relative bg-white overflow-hidden'>
        <div className='absolute inset-0'>
          <div className='absolute inset-y-0 left-0 w-1/2'></div>
        </div>
        <div className='relative max-w-7xl mx-auto lg:grid lg:grid-cols-5'>
          <div
            className='py-16 px-4 sm:px-6 lg:col-span-2  lg:py-20 xl:pr-12'
            data-aos='slide-left'>
            <div className='max-w-lg mx-auto fom-lt'>
              <h2 className='text-4xl font-bold tracking-tight text-neutral-700 sm:text-3xl'>
                We would love to <br />
                hear from you.
              </h2>
              <h4 className='mt-14 text-2xl font-bold font-sans text-neutral-700 leading-6'>
                Our Office Address
              </h4>
              <p className='mt-8 text-lg text-neutral-700 font-normal font-sans leading-6 text-justify'>
                1st Floor, LAPAL House, Suite 1A & B, Smart Office Suites, 235
                Igbosere Street, Lagos Island, Lagos, Nigeria
              </p>
              <h4 className='mt-14 text-2xl font-sans font-bold text-neutral-700 leading-6'>
                Email Us
              </h4>
              <div className='mt-8'>
                <dt className='sr-only'>Email</dt>
                <dd className='flex'>
                  {/* ======Heroicon name: outline/mail =========*/}
                  <svg
                    className='flex-shrink-0 h-6 w-6 '
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'>
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                    />
                  </svg>
                  <span className='ml-3 text-lg text-neutral-700 font-normal font-sans leading-6'>
                    {' '}
                    info@paradigmfinance.ng{' '}
                  </span>
                </dd>
              </div>

              <h4 className='mt-14 text-2xl font-sans font-bold text-neutral-700 leading-6'>
                Or Call
              </h4>
              <div className='mt-8'>
                <dt className='sr-only'>Phone number</dt>
                <dd className='flex'>
                  {/* Heroicon name: outline/phone */}
                  <svg
                    className='flex-shrink-0 h-6 w-6 '
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'>
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z'
                    />
                  </svg>
                  <span className='ml-3 text-lg text-neutral-700 font-normal font-sans leading-6'>
                    {' '}
                    (+234) 090 7077 5627{' '}
                  </span>
                </dd>
              </div>
            </div>
          </div>

          {/*===============FORM PART============*/}
          <div
            className=' lg:w-auto lg:max-w-400 pb-3 bg-white py-5 px-4 sm:px-6 lg:col-span-3 
						lg:mr-8 lg:ml-5
            lg:px-12  border shadow-lg rounded-lg fom-rt'
            style={{}}>
            <img
              className='lg:w-auto pb-3 pl-6 map-in'
              src={map}
              alt='map'
            />

            <div className='max-w-lg mx-auto lg:max-w-none'>
              <form
                action='#'
                method='POST'
                className='grid grid-cols-1 gap-y-6'>
                <div className='col-span-1'>
                  <label
                    for='full-name'
                    className='mb-3 text-sm text-neutral-700 font-normal'>
                    Full name
                  </label>
                  <input

                    type="text"
                    name="full-name"
                    id="full-name"
                    autocomplete="name"
                    className="block w-4/5 py-3 px-4 border placeholder-red-600
										 border-red-600 mt-3 rounded-md"
                  />
                </div>
                <div className="col-span-1">
                  <label
                    for="email"
                    className="text-sm text-neutral-700 font-normal"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    className="block w-full shadow-sm py-3 mt-3 px-2 sm:px-4 border
										 placeholder-red-600 border-red-600 rounded-md"
                  />
                </div>
                <div className='col-span-3 mt-8'>
                  <label
                    for='message'
                    className='text-sm text-neutral-700 font-normal'>
                    Message
                  </label>
                  <textarea
                    id='message'
                    name='message'
                    rows='4'
                    className='block w-full shadow-sm py-3
										 mt-3 px-4 border placeholder-red-600 border-red-600 rounded-md'
                    placeholder=''></textarea>
                </div>
                <div className='sub-btn'>
                  <button
                    type='submit'
                    className='inline-flex align-center justify-center items-center text-center py-3 px-6 border border-transparent 
										shadow-sm text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'>
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* <div
              className="bg-white py-5 min-h-min px-4 sm:px-6 lg:col-span-3 
					lg:px-8 xl:pl-12 border shadow-lg fom-rt rounded-lg"
              data-aos="fade-down"
            >
             
            </div> */}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;

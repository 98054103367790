import React from "react";

import linkedin from "../assets/linkedin.svg";

import "aos/dist/aos.css";

import "../styles.css";

const ProfileBox = ({ data }) => {
  return (
    <div class="content">
      <div class="front">
        <li class="sm:py-8">
          <div className="space-y-4">
            <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-3 overflow-hidden lg:aspect-w-3 lg:aspect-h-3">
              <img
                className="object-center"
                src={data.image}
                alt="data.image"
              />
            </div>

            <div className="space-y-2">
              <div className="leading-6 mb-16 space-y-1">
                <h3 className="uppercase text-lg font-bold">{data.name}</h3>
                <p class="">{data.title}</p>
              </div>
            </div>
          </div>
        </li>
      </div>
      <div class="back">
        <div class="text-xs ">
          <p
            class=""
            style={{
              fontSize: "14px",
              textAlign: "left",
              lineHeight: "16.1px",
            }}
          >
            {data.story}
          </p>
        </div>

        {/* <div class="p-6 rounded-bl-2xl flex justify-center rounded-br-2xl md:px-8">
          <ul role="list" class="flex space-x-5">
            <li>
              <a href="#" class="">
                <span className="inline-block text-xs font-bold">
                  Read More
                </span>
                <span class="sr-only">LinkedIn</span>
                <img
                  className="w-8 h-8 inline-block ml-3"
                  src={linkedin}
                  alt="linkedin"
                  data-aos="zoom-in"
                />
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default ProfileBox;

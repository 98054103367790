import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const GetStartedButton = ({ buttonTitle, action }) => {
  return (
    <Link to="#">
      <div class="mt-6">
        <h3 className="text-base text-neutral-700 font-normal space-x-1">
          {buttonTitle}
          <a
            href="contactus"
            class="text-base font-medium text-red-600 
            hover:text-red-600"
          >
            <span aria-hidden="true" className="arow-l circle">
              {" "}
              &#10140;
            </span>
          </a>
        </h3>
      </div>
    </Link>
  );
};

export default GetStartedButton;
